.DistanceMeasurer {
  color:#ffff;
  border-color:#ffff;

}

.DistanceMeasurerActive {
  border-width:1px 1px 1px 1px;
}

.DistanceMeasurerDeactive {
  border-width:1px 3px 3px 1px;

}