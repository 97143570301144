.ScaleBar {
  position: fixed;
  z-index:2;
  left:5px;
  bottom:35px;
  width:100px;
  border-radius: 5px;
  padding: 5px;
  background-color:rgba(44, 64, 63, 0.8);
  
}

.ScaleBarContent {
  text-align: center;
  color:white;
  border-left:1px dashed white;
  border-right:1px dashed white;
  border-bottom:1px dashed white;
}