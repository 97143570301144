.LegendPopup {
  position: fixed;
  z-index: 2;
  left: 5px;
  bottom: 80px;
  width: 260px;
  max-height: 340px;
  overflow: auto;
  border-radius: 5px;
  padding: 5px;
  background-color: #2c403fcc;
  color: white;
  display:block;
  visibility: visible;
}

.LegendPopup .HorizontalButton{
  background-color: transparent;
}


.LegendButton {
  position: fixed;
  z-index:0;
  left:0px;
  bottom: 70px;
  width:50px;
  padding: 5px;
  text-align: center;
  visibility: hidden;
}

.LegendButton input{
  border:1px solid rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  background-color:rgba(44, 64, 63, 0.8);
  padding:8px 8px;
}


.LegendPopupContent {
  text-align: center;
  color: white;
}

.PopUpLayerItem {
  margin-top: 3px;
}

.PopUpLayerLegendHeader {
  background: transparent;
  display: flex;
  justify-content: space-between;
}

.PopUpLayerLegendHeaderTitle {
  font-weight: bolder;
  font-size: 18px;
}

.PopUpLayerLegendHeaderToggler {
  background: transparent;
  cursor: pointer;
}

.PopUpLayerLegendTitle {
  text-align: start;
  font-weight: bolder;
  font-size: 12px;
}

.LayerOpacity {
  -webkit-appearance: none;
  /* Override default CSS styles */
  appearance: none;
  width: 100%;
  /* Full-width */
  height: 2px;
  /* Specified height */
  background: #86c777;
  /* Grey background */
  outline: none;
  /* Remove outline */
  opacity: 0.7;
  /* Set transparency (for mouse-over effects on hover) */
  -webkit-transition: .2s;
  /* 0.2 seconds transition on hover */
  transition: opacity .2s;
  cursor: pointer;
}

.LayerOpacity::-webkit-slider-thumb {
  -webkit-appearance: none;
  /* Override default look */
  appearance: none;
  width: 10px;
  /* Set a specific slider handle width */
  height: 10px;
  /* Slider handle height */
  background: #86c777;
  /* Green background */
  cursor: pointer;
  border-radius: 10px;
  /* Cursor on hover */
}

.LayerOpacity::-moz-range-thumb {
  width: 10px;
  /* Set a specific slider handle width */
  height: 10px;
  /* Slider handle height */
  background: #86c777;
  /* Green background */
  cursor: pointer;
  /* Cursor on hover */
  border-radius: 10px;
}


@media only screen and (max-height: 950px) {



  .LegendPopup {
    max-height: 160px;
  }


}

@media only screen and (max-height: 820px) {



  .LegendPopup {
    max-height: 140px;
  }


}

@media only screen and (max-height: 780px) {



  .LegendPopup {
    max-height: 110px;
  }


}

@media only screen and (max-height: 750px) {



  .LegendPopup {
    display: none;
  }


}