.FileUploader {
  position: fixed;
  z-index:0;
  left:5px;
  top:435px;
  width:50px;
  padding: 5px;
  text-align: center;
  visibility: hidden;
}

.FileUploader input{
  border:1px solid rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  background-color:rgba(44, 64, 63, 0.8);
  padding:8px 8px;
}

@media only screen and (max-width: 500px) {

  .FileUploader {
    top:430px;
  }
  
  
}