.LayerFolderTitle {
  font-size: 25px;
  cursor: pointer;
  height: 36px;
  white-space: nowrap !important;
  overflow-x: auto !important;
  overflow-y: hidden !important;
  text-overflow: none !important;
}

::-webkit-scrollbar {
    width: 1px; /* Largura da barra de rolagem */
    height: 0px; /* Altura da barra de rolagem (horizontal) */
}

.LayerFolderTitleIcon {
  display: inline-block;
}

.LayerFolderTitleText {
    display: inline-block;
    height: 36px; 
    line-height: 16px; 
    vertical-align: middle;
    text-align: center; 
}

.LayerFolderTitle .material-icons {
  width: 100%;
  height: 100%;
  display: block;
  color: #FFFFFF;
  font-size: 36px;
}


.LayerFolderItem {
  margin-left: 1px;
}

.LayerFolder {
  color: #FFFFFF;
}

.LayerFolder .content {
  border-left: 1px solid #FFFFFF;
  padding-left: 15px;
  margin-left: 15px;
  display: none;
  visibility: hidden;
}

@media only screen and (max-width: 1050px) {

  .LayerFolderTitle {
    font-size: 20px;
    
  }

  .LayerFolderTitleText {
    display: inline-block;
    height: 36px; 
    line-height: 10px; 
    vertical-align: middle;
    text-align: center; 
}

}

@media only screen and (max-width: 800px) {

  .LayerFolderTitle {
    font-size: 18px;
    
  }

  

}

@media only screen and (max-width: 600px) {

  .LayerFolderTitle {

    font-size: 16px;
    
  }

}


@media only screen and (max-width: 550px) {

  .LayerFolderTitle {
    font-size: 15px;
    
  }

}

@media only screen and (max-width: 480px) {

  .LayerFolderTitle {
    font-size: 15px;
    max-width: 200px;
    
  }


}

@media only screen and (max-width: 370px),
screen and (max-height: 800px) {

  .LayerFolderTitle {
    font-size: 15px;
    max-width: 200px;
  }

}

@media only screen and (max-width: 330px) {


  .LayerFolderTitle {
    font-size: 15px;
    max-width: 200px;
    
  }

}