.AreaMeasurer {
  color:#ffffff;
  border-color:#ffffff;
}

.AreaMeasurerActive {
  border-width:1px 1px 1px 1px;
}

.AreaMeasurerDeactive {
  border-width:1px 3px 3px 1px;
}