.BaseLayer {
  position: fixed;
  z-index:0;
  left:5px;
  top:275px;
  width:50px;
  padding: 5px;
  text-align: center;
  visibility: hidden;
}

.BaseLayer input{
  border:1px solid rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  background-color:rgba(44, 64, 63, 0.8);
  padding:8px 8px;
}

.LayerRadio{
  margin-right:5px;
  margin-top: 2px;
  cursor:pointer;
  width: 26px;
  height:26px;
}

.ModalText{
  font-size:18px;
}

@media only screen and (max-width: 500px) {

  .BaseLayer {
    top:270px;
  }
  
  
}