.HeavyLink{
    background-color: transparent;    
}


.HeavyLinkWrapper{
    display: flex;
    justify-content: left;
    align-items: baseline;
    padding: 0;
  }