.LayerSwitcher{
  height:calc(100% - 110px);
  top:110px;
  z-index:4;
  position: fixed;
  border-left:1px solid #86c777;
  right:0px;
  color:white;
  visibility:hidden;
  transition:visibility 0.3s linear,opacity 0.3s linear;
  background-color:rgba(44, 64, 63, 0.8);
  overflow: auto;
  max-width:400px;
}

.LayerSwitcher .HorizontalButton{
  background-color: transparent;
}

.LayerSwitcherLinkWrapper{
  display: flex;
  justify-content: right;
  align-items: baseline;
  padding-bottom: 15px;
}


.LayerSwitcherTitle {
  font-size:35px;
  font-weight: bolder;  
} 

.LayerSwitcherToggler img{
  cursor:pointer;
}


.HeavyLink, .LayerLink{
  font-size:22px;
}

.LayerSwitcher .LayerSwitcherExplorer{
  color:white;
}

@media only screen and (max-width: 1050px) {
 
  .LayerSwitcher .logo img{
    width:420px; 
  }

  .LayerSwitcherTitle{
    font-size:30px;
  }

  .LayerSwitcherToggler img{
    width:40px;
  }

  .HeavyLink{
    font-size:18px;
  }

  .LayerLink{
    font-size:16px;
  }

}

@media only screen and (max-width: 800px) {
 
  .LayerSwitcher .logo img{
    width:380px; 
  }

  .LayerSwitcherTitle{
    font-size:30px;
  }

  .LayerSwitcherToggler img{
    width:35px;
  }
  
  .HeavyLink{
    font-size:16px;
  }

  .LayerLink{
    font-size:14px;
  }

  
}

@media only screen and (max-width: 600px) {
  
  
  .LayerSwitcher .logo img{
    width:350px; 
  }

  .LayerSwitcherTitle{
    font-size:25px;
  }

  .LayerSwitcherToggler img{
    width:30px;
  }

  .HeavyLink{
    font-size:14px;
  }

  .LayerLink{
    font-size:12px;
  }

  .HeavyLink{
    padding: 7px 7px;
  }

}


@media only screen and (max-width: 550px) {
  
  .LayerSwitcher{
    height:calc(100% - 100px);
    top:100px;
  }
  
  .LayerSwitcher .logo img{
    width:300px; 
  }

  .LayerSwitcherTitle{
    font-size:20px;
  }

  .LayerSwitcherToggler img{
    width:25px;
  }

  .LayerLegendTitle{
    font-size:10px;
  }

  .HeavyLink{
    font-size:11px;
  }

  .LayerLink{
    font-size:10px;
  }

  .HeavyLink{
    padding: 6px 6px;
  }


}

@media only screen and (max-width: 480px) {
  
  .LayerSwitcher .logo img{
    width:220px; 
  }

  .LayerSwitcherTitle{
    font-size:18px;
  }

  .LayerSwitcherToggler img{
    width:22px;
  }

  .HeavyLink{
    font-size:10px;
  }

  .LayerLink{
    font-size:9px;
  }

  .HeavyLink{
    padding: 4px 4px;
  }

}

@media only screen and (max-width: 370px), screen and (max-height: 800px) {
  
  .LayerSwitcher .logo img{
    width:200px; 
  }

  .LayerSwitcherTitle{
    font-size:15px;
  }

  .LayerSwitcherToggler img{
    width:20px;
  }

  .HeavyLink, .LayerLink{
    font-size:9px; 
  }

  .HeavyLink{
    padding: 3px 3px;
  }

  .LayerSwitcherLinkWrapper{
    padding-bottom: 5px;
  }

}