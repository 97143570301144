.Toggler{
  padding:12px 18px;
  margin-left:5px;
  color:#FFFFFF !important;
  border-color:#ffff;
  border-width:1px 3px 3px 1px;
  font-weight: bolder;
  font-size:24px;
}

@media only screen and (max-width: 550px) {
  .Toggler{
    padding:13px 10px 13px 10px;
    margin-left:2px;
  }
 }

 @media only screen and (max-width: 280px) {
  
  .Toggler{
    padding:7px 7px;
    margin-left:1px;
  }

 }

 @media only screen and (max-width: 240px) {
  
  .Toggler{
    padding:2px 2px;
    margin-left:1px;
  }

 }

