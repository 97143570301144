.GovFooterBar{
    height:30px;
    bottom: 0px;
    background-color:rgba(221, 221, 217, 0.8);
    position:fixed;
    display: flex;
    font-size:14px;
    z-index:2;
    width:100%;
    align-items: center;
    justify-content: center;
}

.GovFooterBarText{
    text-align: right;
    margin-right: 10px;
    color: rgb(108, 104, 104) !important;
}

.GovFooterBarLink{
    border-left: 1px solid rgb(108, 104, 104) !important;
    text-decoration: none;
    text-align: center;
    padding:0 10px;
}

.GovFooterBar a:link{
    color: rgb(108, 104, 104) !important;
}

.GovFooterBar a:visited{
    color: rgb(108, 104, 104) !important;
}

.GovFooterBar a:hover{
    text-decoration: underline;
}

@media only screen and (max-width: 1000px) {
  
    .GovFooterBar{
        font-size:11px;
    }
    

}

@media only screen and (max-width: 650px) {
  
    .GovFooterBar{
        font-size:9px;
    }
    

}

@media only screen and (max-width: 380px) {
  
    .GovFooterBar{
        font-size:7px;
    }
    

}

@media only screen and (max-width: 320px) {
  
    .GovFooterBar{
        font-size:6px;
    }
    

}