.Welcome {
    width:inherit;
    height:inherit;
    padding:inherit;
    margin:inherit;
    z-index:0;
  }



  .MapWrapper{
    height: 100%;
    width:inherit;
    z-index:1;
  }
