.KMLUploader {
  color:black;
}

.KMLUploader input{
  color:black;
}

.KMLUploader .KMLUploaderHint{
  color: green;
  padding: 5px;
  font-size: 12px;
}

.KMLUploader .KMLUploaderErrorFeedback{
  visibility: hidden;
  color: red;
  padding: 5px;
  font-size: 12px;
}

.KMLUploader .KMLUploaderReset{
  cursor:pointer;
}

@media only screen and (max-width: 500px) {

  .KMLUploader {
    color:black;
  }
  
  
}