.LocateAddressModal .modal-body{
  padding: 10px 70px;
  overflow: auto;
  
}

.LocateAddressModal .modal-dialog{
  max-height: 280px;
}

.LocateAddressModal span{
  font-weight: bold;
}

.modal button:hover{
  background-color: black;
}

.LocateAddressInput{
  width:100%;
}

.LocateAddressButton{
  cursor:pointer;
}