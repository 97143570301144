.HorizontalToolbarToggler {
  position: fixed;
  z-index:2;
  left:5px;
  top:235px;
  width:50px;
  padding: 5px;
  text-align: center;
}

.HorizontalButton{
  border:1px solid rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  background-color:rgba(44, 64, 63, 0.8);
  padding:4px 4px 2px 4px;
}

.ModalText{
  font-size:18px;
}

button .material-icons{
  color:#FFFFFF;
  font-size:24px;
}

@media only screen and (max-width: 500px) {

  .HorizontalToolbarToggler {
    top:230px;
  }
  
  
}