.FontSizeToolbar{
    text-decoration: none;
    text-align: center;
    padding-left:20px;
    margin-left: 30%;
    color: rgb(108, 104, 104) !important;
}

.FontSizeToolbar button{
    margin-left: 5px;
    background: transparent;
    width:30px;
    border: 1px solid gray;
    border-radius: 4px;
    font-style: italic;
    font-weight:bolder;
    
}

@media only screen and (max-width: 1400px) {

    .FontSizeToolbar{
        margin-left: 20%;
    }

}

@media only screen and (max-width: 1200px) {

    .FontSizeToolbar{
        margin-left: 10%;
    }


}

@media only screen and (max-width: 1080px) {

    .FontSizeToolbar{
        margin-left: 5%;
    }

}

@media only screen and (max-width: 980px) {
  

    .FontSizeToolbar{
        margin-left: 60%;
    }

}

@media only screen and (max-width: 900px) {
  
    .FontSizeToolbar{
        margin-left: 50%;
    }

}

@media only screen and (max-width: 690px) {
  
    .FontSizeToolbar{
        margin-left: 40%;
    }

}

@media only screen and (max-width: 580px) {
  
    .FontSizeToolbar{
        margin-left: 20%;
    }

}

@media only screen and (max-width: 420px) {
  
    .FontSizeToolbar{
        margin-left: 15%;
    }

}

@media only screen and (max-width: 360px) {
  
    .FontSizeToolbar{
        margin-left: 5%;
    }

}

@media only screen and (max-width: 320px) {
  
    .FontSizeToolbar{
        margin-left: 1%;
    }

    .FontSizeToolbar{
        font-size:10px;
    }

    .FontSizeToolbar button{
        margin-left: 2px;
        padding:0;
        border-radius: 4px;
    }
    

}

@media only screen and (max-width: 270px) {
  
    .FontSizeToolbar{
        margin-left: 1%;
    }

    .FontSizeToolbar{
        font-size:8px;
    }

    .FontSizeToolbar button{
        margin-left: 1px;
        padding:0;
        border-radius: 4px;
    }
    

}