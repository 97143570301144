.modal, .modal-body , .modal-content{
  border-radius: 20px;
  
}

.modal img{
  border-radius: 20px;
  border: 0;
}

.WelcomeModal .modal-body{
  padding: 10px 70px;
  overflow: auto;
  
}

.WelcomeModalFooter {
  margin-top: 10px;
}

.TermLink{
  color:black;
  font-weight: bolder;
  border-bottom:1px solid black;
  text-decoration: none; 
  font-size:20px;   
}


.TermLink:hover{
  cursor: pointer;
}

.TermCheckBox > label{
  font-size:20px;
}

.TermCheckBox span{
  font-weight:bolder;
}

@media only screen and (max-width: 700px) {
  
  .WelcomeModal .modal-body{
    padding: 5px 10px;
  }


  .WelcomeModalFooter {
    margin: 5px 5px;
  }  

}
