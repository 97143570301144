.LayerFolder {
    margin-top: 0px;
}

.LayerFolderItem {
    margin-top: 10px;
}

.LayerLegend {
    visibility: hidden;
    display: none;
    border-radius: 5px;
    background-color: transparent;
    max-width: 300px;
}

.LayerLegendTitle {
    visibility: hidden;
    display: none;
}

.LayerInputContainer {
    display: flex;
}

.LayerTitleContainer {
    margin-left: 3px;
}

.LayerTitle {
    font-size: 20px;
    text-overflow: ellipsis !important;
    overflow: hidden !important;
}

.LayerCheck {
    width: 22px;
    height: 22px;
    cursor: pointer;
}

@media only screen and (max-width: 1050px) {

    .LayerTitle {
        font-size: 16px;
        max-width: 350px;
    }

    .LayerFolderItem {
        margin-left: 3px;
    }

    .LayerCheck {
        width: 16px;
        height: 16px;
    }

}

@media only screen and (max-width: 800px) {

    .LayerTitle {
        font-size: 15px;
        max-width: 200px;
    }

    .LayerFolderItem {
        margin-left: 3px;

    }

    .LayerTitle {
        font-size: 15px;
        max-width: 280px;
    }

    .LayerCheck {
        width: 16px;
        height: 16px;
    }


}

@media only screen and (max-width: 600px) {

    .LayerTitle {
        font-size: 15px;
        max-width: 240px;
    }

    .LayerFolderItem {
        margin-left: 1px;

    }

    .LayerCheck {
        width: 16px;
        height: 16px;
    }


}


@media only screen and (max-width: 550px) {

    .LayerTitle {
        font-size: 15px;
        max-width: 160px;
    }

    .LayerLegendTitle {
        font-size: 10px;
    }

    .LayerCheck {
        width: 16px;
        height: 16px;
    }

}

@media only screen and (max-width: 480px) {

    .LayerTitle {
        font-size: 15px;
        max-width: 120px;
    }

    .LayerCheck {
        width: 16px;
        height: 16px;
    }

    .LayerLegendTitle {
        font-size: 9px;
    }

}

@media only screen and (max-width: 370px),
screen and (max-height: 800px) {

    .LayerTitle {
        font-size: 15px;
        max-width: 100px;
    }

    .LayerCheck {
        width: 16px;
        height: 16px;
    }

    .LayerLegendTitle {
        font-size: 9px;
    }

}

@media only screen and (max-width: 330px) {

    .LayerTitle {
        font-size: 15px;
        max-width: 80px;
    }

    .LayerCheck {
        width: 16px;
        height: 16px;
    }


}