.Navbar{
    height:80px;
    top:30px;
    background-color:rgba(44, 64, 63, 0.8);
    position:fixed;
    font-size:3vw;
    z-index:3;
    width:100%;
    border-bottom:4px solid #86c777;
}

.Navbar .container-fluid{
    height:70px;
}

.NavBarItem{
    margin-left:auto;
    margin-right:10px;
}

@media only screen and (max-width: 550px) {
  
    .Navbar{
        height:70px;
    }
    
    .Navbar .container-fluid{
        height:60px;
    }

}