.MouseWatcher {
  position: fixed;
  z-index:2;
  left:115px;
  bottom:35px;
  width:150px;
  border-radius: 5px;
  padding: 5px;
  background-color:rgba(44, 64, 63, 0.8);
  display:none;
}

.MouseWatcherContent {
  text-align: center;
  color:white;
  border-left:1px dashed white;
  border-right:1px dashed white;
  border-bottom:1px dashed white;
}

@media (pointer: fine) {
  .MouseWatcher {
    display:inherit;
  }
}