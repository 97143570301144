.StatisticGraphicsModal .modal-body{
  padding: 10px 70px;
  overflow: auto;
  
}

.StatisticGraphicsModal #chartContainer {
  margin: auto;
  overflow: auto;
  /*padding-top: 20px;*/
}

.StatisticGraphicsModal #loader {
  display: none;
}

.StatisticGraphicsModal #exportBtn{
  display: none;
}

.StatisticGraphicsModal #graphicType{
  display: block;
}