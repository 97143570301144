.LayerScope{
   width:100%;
}

@media only screen and (max-width: 600px) {

   .LayerScope{
      width:90%;
   }

}