.LocateMe {
  position: fixed;
  z-index:0;
  left:5px;
  top:355px;
  width:50px;
  padding: 5px;
  text-align: center;
  visibility: hidden;
}

.LocateMeContent button{
  border:1px solid rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  background-color:rgba(44, 64, 63, 0.8);
  margin-top:3px;
  color:white;
  font-size:20px;
  font-weight: bolder;
  padding:2px 10px ;
}

.LocateMe input{
  border:1px solid rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  background-color:rgba(44, 64, 63, 0.8);
 
  padding:8px 8px;
}

@media only screen and (max-width: 500px) {

  .LocateMe {
    top:350px;
  }
  
  
}