.Logo{
  font-weight: bolder;
}

.LogoImage{
  display: inline;
  cursor:pointer;
}

@media only screen and (max-width: 600px) {
 
  .LogoImage{
    max-width:200px;
  }

}

@media only screen and (max-width: 300px) {
 
  .LogoImage{
    max-width:150px;
  }

}

@media only screen and (max-width: 230px) {
 
  .LogoImage{
    max-width:100px;
  }

}