.LayerFilterMin{
    background-color: transparent;    
    display:inline;
    margin:0px 5px;
}

.LayerFilterMinHandler{
    background-color: transparent;    
    display: none;
    padding-top:8px;
}



