.Toolbar{
  overflow: auto;
  margin-left:auto;
  margin-right:0px;
}

.Toolbar button{
  margin-left:5px;
}

.Toolbar button .material-icons{
  color: #FFFFFF;
  font-size:36px;
}


@media only screen and (max-width: 900px) {

  .Toolbar{
    overflow: auto;
    position:absolute;
    bottom:35px;
    right:5px;
    z-index:3;
  }

  .Toolbar button{
    background-color:rgba(44, 64, 63, 0.8);
    padding:8px 8px;
    z-index:3;
    margin-right: 2px;
    border:0;
  }

  .Toolbar button .material-icons {
    font-size:18px;
    color: #FFFFFF;
  }
  
  
}

