.HintModal .modal-body{
  padding: 10px 70px;
  overflow: auto;
  
}

.HintModal span{
  font-weight: bold;
}

.modal button:hover{
  background-color: black;
}

.HintModal .HeavyLink{
  width:100%;
  margin:5px 0 ;
}

.ModalText{
  font-size:16px;
}


.TermLink{
  color:black;
  font-weight: bolder;
  border-bottom:1px solid black;
  text-decoration: none; 
  font-size:18px;   
}

.TermLink:hover{
  cursor: pointer;
}

.TermCheckBox > label{
  font-size:20px;
}

.TermCheckBox span{
  font-weight:bolder;
}
