.TemplateModal {
  z-index:1;
}

.modal-dialog,
.modal-content {
    /* 80% of window height */
    height: 90%;
}

.modal-body {
    /* 100% = dialog height, 120px = header + footer */
    max-height: calc(100% - 100px);
    overflow-y: scroll;
}