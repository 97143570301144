.FeatureInfo {
  color:#ffff;
  border-color:#ffff;
}

.FeatureInfoActive {
  border-width:1px 1px 1px 1px;
  background-color: rgba(255, 255, 255, 0.1);
}

.FeatureInfoDeactive {
  border-width:1px 3px 3px 1px;
}

.FeatureInforRecord{
  margin-bottom:5px;
}

.FeatureField{
  font-weight:bolder;
}
