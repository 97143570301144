.LinkToolbar{
    margin-left: 10%;
}

.LinkToolbarLink{
    border-left: 1px solid rgb(108, 104, 104) !important;
    text-decoration: none;
    text-align: center;
    padding:0 15px;
    color: rgb(108, 104, 104) !important;
}

.LinkToolbar a:link{
    color: rgb(108, 104, 104) !important;
}

.LinkToolbar a:hover{
    text-decoration: none;
}

.LinkToolbar a:visited{
    color: rgb(108, 104, 104) !important;
    text-decoration: none;
}

.LinkToolbar a:active{
    color: rgb(108, 104, 104) !important;
    text-decoration: none;
}

.LinkToolbarToggler{
    text-decoration: none;
    text-align: center;
    padding:2px;
    border-radius:4px;
    margin-left:10px;
    display: none;
}

.LinkToolbarDropdown{
  position: absolute;
  z-index:5;
  top:30px;
  background-color:rgba(221, 221, 217);
  visibility:hidden;
  border-radius:1px;
}

.LinkToolbarDropdown div{
    margin:10px 0px 10px 10px;
}

.LinkToolbarDropdown a{
    text-decoration: none;
}

.LinkToolbarText{
    text-align: right;
    margin-right: 10px;
    color: rgb(108, 104, 104) !important;
}




@media only screen and (max-width: 1200px) {

    .LinkToolbar{
        margin-left: 5%;
    }

}

@media only screen and (max-width: 980px) {
  

    .LinkToolbarLink{
        display: none;
    }

    .LinkToolbarToggler{
        display: block;
    }

}