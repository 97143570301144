.CSVUploader {
  color:black;
}

.CSVUploader input{
  color:black;
}

.CSVUploader .CSVUploaderHint{
  color: green;
  padding: 5px;
  font-size: 12px;
}

.CSVUploader .CSVUploaderErrorFeedback{
  visibility: hidden;
  color: red;
  padding: 5px;
  font-size: 12px;
}

.CSVUploader .CSVUploaderReset{
  cursor:pointer;
}

@media only screen and (max-width: 500px) {

  .CSVUploader {
    color:black;
  }
  
  
}