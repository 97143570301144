.StoryTellingModal .modal-body{
  padding: 10px 70px;
  overflow: auto;
}

.StoryTellingModalCraftingForm{
  display: flex;
  flex-wrap: wrap;
  gap:10px;
}

.StoryTellingModalCraftingForm > div{
  width: 48%;
}

.StoryTellingModalCraftingForm #operatorSection{
  flex-wrap: wrap;
  display: none;
  gap:0 5px;
}

.StoryTellingModalCraftingForm #operatorSection label{
  width:100%;
}

.StoryTellingModalCraftingForm #operatorSection select, .StoryTellingModalCraftingForm #operatorSection input{
  width:40%;
  height:40px;
}

#operatorSection, #fieldZSection, #chartTypeSection{
  display: none;
}

.addSectionContainer{
  width: 100% !important;
  display: flex;
  flex-wrap: wrap;
  flex-flow: row-reverse;
  gap:5px;
  margin-right: 30px;
}

.StoryTellingModal .section-container {
  border: 1px solid #ccc;
  margin-bottom: 20px;
  padding: 15px;
}

.StoryTellingModal .section-title {
  font-weight: bold;
  font-size: 1.2em;
  margin-bottom: 10px;
}

.StoryTellingModal .section-description {
  margin-bottom: 10px;
}

.StoryTellingModal .section-type {
  font-weight: bold;
  margin-bottom: 10px;
}

.StoryTellingModal #chartContainer {
  margin: auto;
  overflow: auto;
}

.StoryTellingModal #loader {
  display: none;
}

.StoryTellingModal #exportSection{
  display: none;
}

.StoryTellingModal #removeSection{
  display: none;
}